import React from 'react';
import './DemoPage.css';

class DemoPage extends React.Component { 
    render() {
        return (
            <section className='demo-page'>
                Wassssup
            </section>
        )
    }
}

export default DemoPage;